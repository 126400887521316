import React from 'react'
import { RiVerifiedBadgeFill } from "react-icons/ri";

const Profile = () => {
  return (
    <div className='max-w-sm mx-auto text-center grid gap-2 -mt-16'>
      <section className='grid justify-center'>
        <img src={ require('../assets/img/agam-riyandana.jpg') } alt="Foto Profil" className='h-28 rounded-full' />
      </section>
      <section className="mx-auto align-middle">
        <p className="flex mt-4 text-xl font-bold text-slate-900 dark:text-white ">
          Agam Riyandana{' '}
          <span className="pl-2 text-2xl text-blue-700">
            <RiVerifiedBadgeFill />
          </span>
        </p>
      </section>
      <section className='text-sm'>
        <p className=''>Based in 📌 Kota Jember, Indonesia</p>
        <p> 
          <span>  </span>I'm a Digital Creators
        </p>
      </section>
      <section className=''>
      </section>
    </div>
  )
}
export default Profile
