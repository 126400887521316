import React from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaSquareThreads } from "react-icons/fa6";
import { SiBluesky } from "react-icons/si";


const Sosmed = ({darkTheme}) => {
  return (
    <div className='flex justify-center items-center text-4xl gap-4 max-w-sm mx-auto text-slate-500 dark:text-slate-400'>
      <a href="https://www.facebook.com/agamriyandana.id" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <FaFacebookSquare /></a>
      <a href="https://www.instagram.com/agam_riyandana1/" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <FaSquareInstagram /></a>
      <a href="https://www.threads.net/@agam_riyandana1" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <FaSquareThreads /></a>
      <a href="https://www.twitter.com/agam_riyandana1/" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <FaSquareXTwitter /></a>
      <a href="https://www.linkedin.com/in/agam-riyandana/" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <IoLogoLinkedin /></a>
      <a href="https://bsky.app/profile/agamriyandana.my.id" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <SiBluesky /></a>
      <a href="mailto:admin@agamriyandana.my.id" className={`hover:scale-105 active:scale-95 transition ${darkTheme ? 'hover:text-slate-200 active:text-white' : 'hover:text-slate-600 active:text-slate-900'}`}> <MdEmail /></a>
    </div>
  )
}
export default Sosmed
