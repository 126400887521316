import React from 'react'

const Button = ({darkTheme}) => {
  return (
    <div className='max-w-sm mx-auto grid justify-center gap-3 my-4 font-bold'>
      <a href="https://i-de.agamriyandana.my.id" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-custom-800 hover:bg-custom-700 active:bg-custom-900' : 'text-slate-900 bg-custom hover:bg-custom-100 active:bg-slate-200'}`}> 
      IDE Online 🧑‍💻
      </a>
      <a href="https://book-hub.agamriyandana.my.id" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-custom-700 active:bg-slate-900' : 'text-slate-900 bg-custom hover:bg-custom-100 active:bg-slate-200'}`}> 
      BOOK HUB 📑
      </a>
      <a href="https://portofolio.agamriyandana.my.id" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-slate-700 active:bg-slate-900' : 'text-slate-900 bg-white hover:bg-slate-100 active:bg-slate-200'}`}> 
      Website Portofolio 👀
      </a>
      <a href="https://e-signature.agamriyandana.my.id" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-slate-700 active:bg-slate-900' : 'text-slate-900 bg-white hover:bg-slate-100 active:bg-slate-200'}`}> 
      Buat E-Signature Free Buat Disini ✍️
      </a>
      <a href="https://agam-digital.my.id" className={`rounded-2xl text-center border-amber-200 border-2 py-3 px-24 shadow-md shadow-amber-100 hover:scale-105 active:scale-95 transition ${darkTheme ? 'text-white bg-slate-800 hover:bg-slate-700 active:bg-slate-900' : 'text-slate-900 bg-white hover:bg-slate-100 active:bg-slate-200'}`}> 
      Blog 👀
      </a>
    </div>
  )
}
export default Button
