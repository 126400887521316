import React from 'react'

const Footer = ({isSticky}) => {
  return (
    <div className='max-w-sm mx-auto flex justify-center'>
      <p className="copyright m-0"> © 2022 - {new Date().getFullYear()} Agam Riyandana All Rights Reserved.</p>
    </div>
  )
}
export default Footer
